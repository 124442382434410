@mixin changeText($text) {
    text-indent: -9999px;
    line-height: 0; /* Collapse the original line */
    &:after {
        content: $text;
        text-indent: 0;
        display: block;
        line-height: initial;
    }
}

@mixin comicSans {
    font-family: 'Comic Sans MS', 'Comic Sans', cursive !important;
}

@mixin color($back, $fore) {
    background-color: $back;
    color: $fore;
}

@mixin egg($back, $fore, $text) {
    * {
        @include color($back, $fore);
        @include comicSans();
        @include changeText($text);
    }
}

/*@media (max-width: 369px) {
    @include egg(magenta,yellow,"Your screen is too small to be supported, sorry!");
}*/
// @media (width: 420px) and (height: 420px) {
//     @include egg(darkgreen, lightgreen, 'Smoke weed everyday');
// }
// @media (width: 666px) and (height: 666px) {
//     @include egg(darkred, red, '666');
// }
